.curved-section {
    position: relative;
    overflow: hidden;
    background-color: #f8f9fa; /* Background color for the bottom section */
  }
  
  .curved-section-content {
    position: relative;
    z-index: 1;
  }
  
  .curved-section-top {
    padding: 60px 20px;
    background-color: #007bff; /* Background color for the top section */
    color: white;
    text-align: center;
  }
  
  .curved-section-bottom {
    padding: 60px 20px;
    background-color: #f8f9fa; /* Background color for the bottom section */
    color: #333;
    text-align: center;
  }
  
  .curved-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
  
  @media (max-width: 768px) {
    .curved-section-top, .curved-section-bottom {
      padding: 40px 20px;
    }
  }
  