.accordion-item {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .accordion-header {
    background-color: #ffffff;
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .accordion-header h5
  {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    margin: 0 30px 0 0;
    transition: 0.3s;
    cursor: pointer;
    color:#47b2e4;
  }
  
  .accordion-content {
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  