.container {
  text-align: center;
}

.filter-buttons {
  display: flex;
  justify-content: right;
  gap: 10px;
}

.media-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px; 
}

.gallery-item {
  width: 100%;
  height: auto;
  cursor: pointer;
  border: 2px solid #ddd;
 
  transition: transform 0.2s;
  object-fit: cover;
  height: 150px;
}

.gallery-item:hover {
  transform: scale(1.05);
}

.gallery-item video {
  width: 100%;
  height: auto;
 
}

.gallery-content
{
  margin-top: 120px !important;
}
 
.btn-primary1 {
  --bs-btn-color: #f5f5f5 !important;
  --bs-btn-bg: #35557a !important;
}
.btn:hover {
  color: white;
  background-color: #35557a;   
}