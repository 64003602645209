.hero {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  padding: 60px 0;
}

.container1 {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.imgDisabled {
  filter: grayscale(100%);
}

.hero .container {
  position: relative;
  z-index: 2;
}

.hero .hero-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.hero a {
  align-content: center;
}

.hero .btn-get-started {
  background-color: #007bff;
  color: white;
  padding: 10px 30px;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.hero .btn-get-started:hover {
  background-color: #0056b3;
}

.hero .btn-watch-video {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  padding: 10px 30px;
  border-radius: 50px;
  text-decoration: none;
  margin-left: 20px;
  transition: background-color 0.3s ease;
  display: none !important;
}

.hero .btn-watch-video:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.divIconosContactosMobil {
  background-color: rgb(255 255 255 / 37%);
  border-radius: 30px;
  display: none !important;
}

.divIconosContactosMobil a {
  margin: 5px 40px;
}

.logoBannerMobil {
  font-size: x-large;
  color: white;
  text-align: -webkit-center;
}

.logoBannerMobil img {
  width: 80%  !important;
  display: none; 
  background-color: #f0f8ff33 !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;  
  border-radius: 800px !important;
}

.divLeaveMessageMobil {
  display: none !important;
  font-size: x-large;
  color: white;
}

@media (max-width: 1000px) {
  .hero {
    padding: 40px 0;
    background-attachment: scroll;
  }

  .hero h1 {
    font-size: 2rem;
    text-align: center;
  }

  .hero p {
    font-size: 1rem;
    text-align: center;
  }

  .hero .hero-img {
    display: none;
  }

  .hero .d-flex {
    align-self: center;
  }

  .btn-get-started,
  .btn-watch-video {
    display: none !important;
  }

  .logoBannerMobil img {
    width: 100%;
    display: block;
  }
  
  .divLeaveMessageMobil {
    display: block !important;
  }
  
  .divIconosContactosMobil {
    display: block !important;
  }
  
  .sitename {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 40px 0;
    background-attachment: scroll;
    background-size: contain;
    background-position: center top;
  }

  .hero h1 {
    font-size: 2rem;
    text-align: center;
  }

  .hero p {
    font-size: 1rem;
    text-align: center;
  }

  .hero .hero-img {
    display: none;
  }

  .hero .d-flex {
    align-self: center;
  }

  .btn-get-started,
  .btn-watch-video {
    display: none !important;
  }
  
  .logoBannerMobil img {
    width: 100%;
    display: block;
  }
  
  .divLeaveMessageMobil {
    display: block!important;
  }
  
  .divIconosContactosMobil {
    display: block !important;
  }
  
  .sitename {
    display: none;
  }
}

@media (max-width: 576px) {
  .hero {
    padding: 20px 0;
    background-attachment: scroll;
     background-size: cover;
    background-position: center;
  }

  .hero h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .hero p {
    font-size: 0.875rem;
    text-align: center;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    padding: 8px 20px;
    font-size: 0.875rem;
  }

  .hero .d-flex {
    align-self: center;
  }

  .btn-get-started,
  .btn-watch-video {
    display: none !important;
  }
  
  .logoBannerMobil img {
    width: 100%;
    display: block;
    filter: brightness(1);
  }
  
  .divLeaveMessageMobil {
    display: block!important;
  }
  
  .divIconosContactosMobil {
    display: block !important;
  }
  
  .sitename {
    display: none;
  }
}
